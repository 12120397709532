import React, { createContext, useState } from 'react';

import { useResize } from '../hooks/useResize';
import { Instance } from 'types';

export interface LookAt {
  position: [number, number, number];
  rotation?: [number, number, number];
}

type SelectedCategory = [string, Instance] | null;

export interface Tooltip {
  title: string;
  x: number;
  y: number;
}

interface UIContextProps {
  // the active section of   the house, if any
  activeSection: string | null;

  activeBundle: boolean;

  introPlayed: boolean;

  // the currently selected product category that's zoomed in on
  selectedCategory: SelectedCategory;

  tooltip: Tooltip | null;

  isMobile: boolean;

  isDesktop: boolean;

  dragTip: boolean;

  setActiveBundle(b: boolean): void;
  setSelectedCategory(s: SelectedCategory): void;
  setActiveSection(s: string | null): void;
  setIntroPlayed(played: boolean): void;
  setTooltip(t: Tooltip | null): void;
  setDragTip(t: boolean): void;
}

export const UIContext = createContext<UIContextProps>({
  activeSection: null,
  setActiveSection() {
    /* empty */
  },
  introPlayed: false,
  setIntroPlayed() {
    /* empty */
  },

  selectedCategory: null,

  setSelectedCategory() {
    /* empty */
  },

  tooltip: null,
  setTooltip() {
    /* empty */
  },

  isMobile: false,
  isDesktop: true,

  dragTip: false,
  setDragTip() {
    /* empty  */
  },

  activeBundle: false,
  setActiveBundle() {
    /* empty  */
  },
});

export const UIContextProvider: React.FC = ({ children }) => {
  const [introPlayed, setIntroPlayed] = useState(false);
  const [activeBundle, setActiveBundle] = useState(false);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory>(null);
  const [isMobile, isDesktop] = useResize();

  const [tooltip, setTooltip] = useState<Tooltip | null>(null);
  const [dragTip, setDragTip] = useState(true);

  const handleSetActiveSection = (section: string | null) => {
    setActiveSection(section);
    setSelectedCategory(null);
    if (section !== 'bundles') {
      setActiveBundle(false);
    }
  };

  return (
    <UIContext.Provider
      value={{
        introPlayed,
        setIntroPlayed,
        activeSection,
        setActiveSection: handleSetActiveSection,
        selectedCategory,
        setSelectedCategory,
        setTooltip,
        tooltip,
        isMobile,
        isDesktop,
        setDragTip,
        dragTip,
        activeBundle,
        setActiveBundle,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
