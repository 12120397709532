import { stripUnit } from 'polished';
import { css } from 'styled-components';

import { breakpoints, variables } from './variables';

export function responsiveFont(minSize?: number | string, size?: number | string, limit?: number | string) {
  return function responseFontFn(props) {
    minSize = stripUnit(minSize || variables.font.sizeMin);
    size = stripUnit(size || variables.font.size);
    limit = limit || variables.pageLimit();
    if (typeof limit === 'function') {
      limit = (limit as any)(props);
    }

    const baseline = stripUnit(breakpoints.sm);
    const fontMultiplier = ((size as number) - (minSize as number)) / (stripUnit(limit) - baseline);
    const fontBaseline = (minSize as number) - fontMultiplier * baseline;

    // prettier-ignore
    return css`
      font-size: ${minSize}px;
      font-size: calc(${minSize}px * var(--scale-font));

      @media (min-width: ${breakpoints.sm}) {
        font-size: ${minSize}px;
        font-size: calc(${fontMultiplier} * 100vw + (${fontBaseline}px * var(--scale-font)));
      }

      @media (min-width: ${variables.pageLimit}) {
        font-size: ${minSize}px;
        font-size: calc(${size}px * var(--scale-font));
      }
    `;
  };
}

export function formatPrice(price: number, showZero?: boolean, showDecimals?: boolean) {
  if (!price || price === 0) {
    return showZero ? '0 kr.' : '—';
  }

  const decimal = showDecimals ? 2 : 0;

  return `${price.toFixed(decimal).replace('.', ',').replace(/./g, (c, i, a) => i && c !== ',' && ((a.length - i) % 3 === 0) ? `.${c}` : c)} kr.`;
}

export function formatHeading(title) {
  let lastChar = '';
  const cleanTitle = title.trim();
  if (cleanTitle) {
    lastChar = cleanTitle.slice(-1);
    if (lastChar === '?' || lastChar === '!' || lastChar === '.') {
      return cleanTitle.slice(0, -1);
    }
  }
  return cleanTitle;
}

export function getPunctuation(title) {
  let lastChar = '';
  const cleanTitle = title.trim();
  if (cleanTitle) {
    lastChar = cleanTitle.slice(-1);
    if (lastChar === '?' || lastChar === '!') {
      return lastChar;
    }
  }
  return false;
}

export const fit = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
