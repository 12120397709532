import { css } from 'styled-components';

export const fonts = css`
  @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700');

  @font-face {
    font-family: 'Avant Garde Gothic ITC';
    font-style: normal;
    font-weight: 100;
    src: url(${require('../assets/fonts/367117_2_0.eot')});
    src: url(${require('../assets/fonts/367117_2_0.eot')}?#iefix) format('embedded-opentype'),
      url(${require('../assets/fonts/367117_2_0.woff2')}) format('woff2'),
      url(${require('../assets/fonts/367117_2_0.woff')}) format('woff'),
      url(${require('../assets/fonts/367117_2_0.ttf')}) format('truetype');
  }

  @font-face {
    font-family: 'Avant Garde Gothic ITC';
    font-style: normal;
    font-weight: 400;
    src: url(${require('../assets/fonts/367117_1_0.eot')});
    src: url(${require('../assets/fonts/367117_1_0.eot')}?#iefix) format('embedded-opentype'),
      url(${require('../assets/fonts/367117_1_0.woff2')}) format('woff2'),
      url(${require('../assets/fonts/367117_1_0.woff')}) format('woff'),
      url(${require('../assets/fonts/367117_1_0.ttf')}) format('truetype');
  }

  @font-face {
    font-family: 'Avant Garde Gothic ITC';
    font-style: normal;
    font-weight: 500;
    src: url(${require('../assets/fonts/367117_3_0.eot')});
    src: url(${require('../assets/fonts/367117_3_0.eot')}?#iefix) format('embedded-opentype'),
      url(${require('../assets/fonts/367117_3_0.woff2')}) format('woff2'),
      url(${require('../assets/fonts/367117_3_0.woff')}) format('woff'),
      url(${require('../assets/fonts/367117_3_0.ttf')}) format('truetype');
  }

  @font-face {
    font-family: 'Avant Garde Gothic ITC';
    font-style: normal;
    font-weight: 600;
    src:
      url(${require('../assets/fonts/itc-avantgardestd-demi.woff2')}) format('woff2'),
      url(${require('../assets/fonts/itc-avantgardestd-demi.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Avant Garde Gothic ITC';
    font-style: normal;
    font-weight: 700;
    src: url(${require('../assets/fonts/367117_0_0.eot')});
    src: url(${require('../assets/fonts/367117_0_0.eot')}?#iefix) format('embedded-opentype'),
      url(${require('../assets/fonts/367117_0_0.woff2')}) format('woff2'),
      url(${require('../assets/fonts/367117_0_0.woff')}) format('woff'),
      url(${require('../assets/fonts/367117_0_0.ttf')}) format('truetype');
  }
`;
