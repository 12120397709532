import React, { useRef } from 'react';
import { createContext, useState } from 'react';
import { Texture } from 'three';
import * as loaders from '../utils/loaders';
import fetch from 'isomorphic-fetch';
import { GLTF } from '../utils/GLTFLoader';
import chunk from 'lodash/chunk';
import throttle from 'lodash/throttle';
import axios from 'axios';
import { manifest } from './christmas-manifest';
import { ManifestEntry, BINARY, IMAGE } from './ManifestEntry';
import { isMobile } from '../utils/is-mobile';

export const INITIAL = 0;
export const LOADING = 1;
export const RENDERING = 2;
export const POST_RENDER_CONFIG = 3;
export const DONE = 4;
export const INTERACTIVE = 5;

export interface LoadingContextProps {
  loadingState: number;
  progress: number;
  total: number;
  totalBytes: number;
  recvBytes: number;
  start(): void;
  getModel(id: string): Promise<GLTF>;

  getTexture(id: string): Promise<Texture>;
  setLoadingState(state: number): void;
  get<T>(id: string): T;
}

export const LoadingContext = createContext<LoadingContextProps>({} as any);

type LoadingState = Omit<
  LoadingContextProps,
  'getTexture' | 'get' | 'getModel' | 'start' | 'setLoadingState' | 'getFont'
>;

export const LoadingProvider: React.FC = ({ children }) => {
  const [state, set] = useState<LoadingState>({
    loadingState: INITIAL,
    progress: 0,
    recvBytes: 0,
    totalBytes: 0,
    total: manifest.length,
  });

  const [recv] = useState(() => new Map());

  const cache = useRef(new Map());

  const startLoading = async () => {
    if (state.loadingState > INITIAL) {
      return;
    }

    for (const key of recv.keys()) {
      recv.set(key, 0);
    }

    set((s) => ({
      ...s,
      loadingState: LOADING,
      progress: 0,
    }));

    const tz = await totalSize(manifest);

    if (tz > 0) {
      set((s) => ({
        ...s,
        totalBytes: tz,
      }));
    }

    for (const ch of chunk(manifest, 10)) {
      await Promise.all(ch.map(loadItem));
    }

    set((s) => ({ ...s, loadingState: RENDERING }));
  };

  const totalSize = async (mani: ManifestEntry[]) => {

    // these numbers were acquired by running the commented out code 
    // we can't depend on the existence of a content-length header
    if (!isMobile) {
      return 35183699;
    } else {
      return 20196825;
    }

    // const fetchHead = (src: string) =>
    //   fetch(`${src}?`, {
    //     method: 'HEAD',
    //     headers: {
    //       pragma: 'no-cache',
    //       'cache-control': 'no-cache',
    //     },
    //   }).then((x) => +x.headers.get('Content-Length')!);

    // const lengths = await Promise.all(
    //   mani
    //     .map((s) => s.src)
    //     .filter((x) => !/^data:/.test(x))
    //     .map(fetchHead)
    // );

    // const totalSize = lengths.reduce((sum, n) => sum + n, 0);

    // console.log(totalSize, isMobile ? 'mobile' : 'desktop');
    // return totalSize;
  };

  const updateProgressBytes = throttle(
    () => {
      const recvBytes = Array.from(recv.values()).reduce(
        (sum, n) => sum + n,
        0
      );
      set((s) => ({ ...s, recvBytes }));
    },
    200,
    { trailing: true }
  );

  const onProgress = (id: string, loaded: number) => {
    recv.set(id, loaded);
    updateProgressBytes();
  };

  const loadItem = async (asset: ManifestEntry) => {
    const res = await axios.get(asset.src, {
      responseType:
        asset.type === BINARY
          ? 'arraybuffer'
          : asset.type === IMAGE
          ? 'blob'
          : 'text',
      onDownloadProgress: (e) => onProgress(asset.id, e.loaded),
    });

    let { data } = res;
    if (asset.type === IMAGE) {
      data = URL.createObjectURL(data);
    }

    cache.current.set(asset.id, data);
    set((s) => ({ ...s, progress: s.progress + 1 }));
  };

  function get<T>(id: string): T {
    return cache.current.get(id);
  }

  const getModel = (id: string): Promise<GLTF> => {
    const data = get(id);
    return new Promise((resolve, reject) => {
      loaders.gltf.parse(data as any, '', resolve, reject);
    });
  };

  const getTexture = (id: string): Promise<Texture> => {
    return new Promise((resolve, reject) => {
      const url = get<string>(id);
      if (url) {
        loaders.texture.load(url, resolve, reject);
        return;
      }

      reject(`Texture with id ${id} missing!`);
    });
  };

  const setLoaded = (state: number) => {
    set((s) => ({ ...s, loadingState: state }));
  };

  return (
    <LoadingContext.Provider
      value={{
        ...state,
        total: manifest.length,
        start: startLoading,
        getModel,
        getTexture,
        setLoadingState: setLoaded,
        get,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
