const title = 'Snjallheimilið';
const description = 'Láttu eins og heima hjá þér! Kíktu í heimsókn í snjallheimili Nova þar sem þú getur stýrt stemningunni, haft öryggið snjallt og sótt þér alla þá afþreyingu sem þú girnist.';

const favicon = require('../assets/images/favicon.png');
const share = require('../assets/images/share-social.jpg');

export const helmet = {
  title,
  titleTemplate: '%s - NOVA',
  htmlAttributes: { lang: 'en' },
  meta: [
    { name: 'description', content: description },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'msapplication-navbutton-color', content: '#000' },
    { name: 'msapplication-TileColor', content: '#000' },
    { name: 'theme-color', content: '#000' },

    { property: 'og:title', content: title },
    { property: 'og:image', content: share },
    { property: 'og:image:width', content: '1200px' },
    { property: 'og:image:height', content: '630px' },
    { property: 'og:image:alt', content: description },

    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: share },
    { name: 'twitter:site', content: '@nova_island' },
    { name: 'twitter:creator', content: '@nova_island' },
    { name: 'twitter:description', content: description },
  ],
  link: [{ rel: 'icon', type: 'image/x-icon', href: favicon }],
};
