import { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { breakpoints } from '../styles/variables';

export const useResize = () => {
  const [isMobile, setMobile] = useState<boolean>(true);
  const [isDesktop, setDesktop] = useState<boolean>(true);

  useEffect(() => {
    const setSizes = () => {
      setMobile(!window.matchMedia(`(min-width: ${breakpoints.md})`).matches);
      setDesktop(window.matchMedia(`(min-width: ${breakpoints.lg})`).matches);
    };

    const handleResize = _debounce(() => {
      setSizes();
    }, 100);

    setSizes();

    window.addEventListener('resize', handleResize, { passive: true });
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [isMobile, isDesktop];
};
