import { isMobile } from '../utils/is-mobile';
import { ManifestEntry, BINARY, IMAGE } from './ManifestEntry';


export const manifest: ManifestEntry[] = [
  { src: require('../assets/models/christmas/cat_christmas.glb'), type: BINARY, id: 'cat' },
  { src: require('../assets/models/robber.glb'), type: BINARY, id: 'robber' },
  { src: require('../assets/models/clouds.glb'), type: BINARY, id: 'clouds' },
  { src: require('../assets/models/christmas/bird_christmas.glb'), type: BINARY, id: 'bird' },
  { src: require('../assets/models/devices/antenna.glb'), type: BINARY, id: 'antenna' },
  { src: require('../assets/models/devices/camera.glb'), type: BINARY, id: 'camera' },
  { src: require('../assets/models/devices/doorbell.glb'), type: BINARY, id: 'doorbell' },
  { src: require('../assets/models/devices/extender.glb'), type: BINARY, id: 'extender' },
  { src: require('../assets/models/devices/hub.glb'), type: BINARY, id: 'hub' },
  { src: require('../assets/models/devices/router.glb'), type: BINARY, id: 'router' },
  { src: require('../assets/models/devices/sensor.glb'), type: BINARY, id: 'sensor' },
  { src: require('../assets/models/devices/smoke_detector.glb'), type: BINARY, id: 'smoke-detector' },
  { src: require('../assets/models/devices/speaker.glb'), type: BINARY, id: 'speaker' },
  { src: require('../assets/models/devices/thermo.glb'), type: BINARY, id: 'thermo' },
  { src: require('../assets/images/textures/devices.jpg'), type: IMAGE, id: 'devices_texture' },
  { src: require('../assets/images/nova.jpg'), type: IMAGE, id: 'nova_texture' },
  { src: require('../assets/images/alpha.jpg'), type: IMAGE, id: 'alphamap' },
  { src: require('../assets/images/cube/posx.jpg'), type: IMAGE, id: 'cube-posx' },
  { src: require('../assets/images/cube/negx.jpg'), type: IMAGE, id: 'cube-negx' },
  { src: require('../assets/images/cube/posy.jpg'), type: IMAGE, id: 'cube-posy' },
  { src: require('../assets/images/cube/negy.jpg'), type: IMAGE, id: 'cube-negy' },
  { src: require('../assets/images/cube/posz.jpg'), type: IMAGE, id: 'cube-posz' },
  { src: require('../assets/images/cube/negz.jpg'), type: IMAGE, id: 'cube-negz' },
  { src: require('../assets/images/icons/camera.png'), type: IMAGE, id: 'icon_camera' },
  { src: require('../assets/images/icons/doorbell.png'), type: IMAGE, id: 'icon_doorbell' },
  { src: require('../assets/images/icons/doorlock.png'), type: IMAGE, id: 'icon_doorlock' },
  { src: require('../assets/images/icons/light_bar.png'), type: IMAGE, id: 'icon_lightbar' },
  { src: require('../assets/images/icons/light_control.png'), type: IMAGE, id: 'icon_sensor' },
  { src: require('../assets/images/icons/router.png'), type: IMAGE, id: 'icon_router' },
  { src: require('../assets/images/icons/smoke_detector.png'), type: IMAGE, id: 'icon_smoke-detector' },
  { src: require('../assets/images/icons/speaker.png'), type: IMAGE, id: 'icon_speaker' },
  { src: require('../assets/images/icons/thermo.png'), type: IMAGE, id: 'icon_thermo' },
  { src: require('../assets/images/icons/wifi_extender.png'), type: IMAGE, id: 'icon_extender' },
  { src: require('../assets/images/icons/hub.png'), type: IMAGE, id: 'icon_hub' },
  { src: require('../assets/images/icons/bulb.png'), type: IMAGE, id: 'icon_bulb' },
  { src: require('../assets/images/icons/ceilinglight.png'), type: IMAGE, id: 'icon_ceilinglight' },
  { src: require('../assets/images/icons/soundbar.png'), type: IMAGE, id: 'icon_soundbar' },
  { src: require('../assets/images/icons/nova_tv.png'), type: IMAGE, id: 'icon_tv' },
  { src: require('../assets/images/icons/scooter.png'), type: IMAGE, id: 'icon_scooter' },
  { src: require('../assets/images/textures/snowflake.png'), type: IMAGE, id: 'snowflake_texture' },
];

// prettier-ignore
if (isMobile) {
  manifest.push({ src: require('../assets/models/christmas/house_christmas_textured_m.glb'), type: BINARY,                             id: 'house' });
  manifest.push({ src: require('../assets/models/christmas/exterior_christmas_textured_m.glb'), type: BINARY,                          id: 'exterior' });
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_bedroom_top_left_night_m.jpg'), type: IMAGE,      id: 'bedroom_top_left_night_texture'      })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_bedroom_top_right_night_m.jpg'), type: IMAGE,     id: 'bedroom_night_texture'               })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_building_frame_night_m.jpg'), type: IMAGE,        id: 'building_frame_night_texture'        })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_dinner_room_night_m.jpg'), type: IMAGE,           id: 'dining_room_night_texture'           })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_doors_m.jpg'), type: IMAGE,                       id: 'doors_night_texture'                 })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_floor_night_m.jpg'), type: IMAGE,                 id: 'floor_night_texture'                 })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_garage_entrance_door_night_m.jpg'), type: IMAGE,  id: 'garage_entrance_door_night_texture'  })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_garage_night_m.jpg'), type: IMAGE,                id: 'garage_hallway_night_texture'        })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_lights_m.jpg'), type: IMAGE,                      id: 'Bulbs_night_texture'        })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_houses_night_m.jpg'), type: IMAGE,                id: 'houses_night_texture'                })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_kitchen_night_m.jpg'), type: IMAGE,               id: 'kitchen_night_texture'               })
  // this ID is capitalized vs the normal manifest because the christmas house model has different mesh names 
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_roof_night_m.jpg'), type: IMAGE,                  id: 'Roof_night_texture'                  })  
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_cars_night_m.jpg'), type: IMAGE,                  id: 'cars_night_texture'                  })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_stairs_windows_night_m.jpg'), type: IMAGE,        id: 'stairs_window_night_texture'         })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_walls_night_m.jpg'), type: IMAGE,                 id: 'walls_night_texture'                 })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_living_room_night_m.jpg'), type: IMAGE,           id: 'LivingRoomFurniture_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_shoesjacketcandle_night_m.jpg'), type: IMAGE,     id: 'ShoesJacketCandle_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_tree_gifts_night_m.jpg'), type: IMAGE,            id: 'LivingRoomTree_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_snow_night_m.jpg'), type: IMAGE,                  id: 'Snow_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_toys_night_m.jpg'), type: IMAGE,                  id: 'ToysWreath_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/cloud_night_m.jpg'), type: IMAGE,                           id: 'Cloud_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_snowman_night_m.jpg'), type: IMAGE,                           id: 'SnowMan_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_trees_night_m.jpg'), type: IMAGE,                           id: 'Trees_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/mobile/christmas_candle_night_m.jpg'), type: IMAGE,                           id: 'Candle_night_texture'   })
  
} else {
  manifest.push({ src: require('../assets/models/christmas/house_christmas_textured.glb'), type: BINARY,                               id: 'house' });
  manifest.push({ src: require('../assets/models/christmas/exterior_christmas_textured.glb'), type: BINARY,                            id: 'exterior' });
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_bedroom_top_left_night.jpg'), type: IMAGE,               id: 'bedroom_top_left_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_bedroom_top_right_night.jpg'), type: IMAGE,              id: 'bedroom_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_building_frame_night.jpg'), type: IMAGE,                 id: 'building_frame_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_dinner_room_night.jpg'), type: IMAGE,                    id: 'dining_room_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_doors.jpg'), type: IMAGE,                                id: 'doors_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_floor_night.jpg'), type: IMAGE,                          id: 'floor_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_garage_entrance_door_night.jpg'), type: IMAGE,           id: 'garage_entrance_door_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_garage_night.jpg'), type: IMAGE,                         id: 'garage_hallway_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_lights.jpg'), type: IMAGE,                               id: 'Bulbs_night_texture'        })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_houses_night.jpg'), type: IMAGE,                         id: 'houses_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_kitchen_night.jpg'), type: IMAGE,                        id: 'kitchen_night_texture' })
  
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_roof_night.jpg'), type: IMAGE,                           id: 'Roof_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_cars_night.jpg'), type: IMAGE,                           id: 'cars_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_stairs_windows_night.jpg'), type: IMAGE,                 id: 'stairs_window_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_trees_night.jpg'), type: IMAGE,                          id: 'Trees_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_walls_night.jpg'), type: IMAGE,                          id: 'walls_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_living_room_night.jpg'), type: IMAGE,                    id: 'LivingRoomFurniture_night_texture' })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_shoesjacketcandle_night.jpg'), type: IMAGE,              id: 'ShoesJacketCandle_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_tree_gifts_night.jpg'), type: IMAGE,                     id: 'LivingRoomTree_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_snow_night.jpg'), type: IMAGE,                           id: 'Snow_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_toys_night.jpg'), type: IMAGE,                           id: 'ToysWreath_night_texture'   })
manifest.push({ src: require('../assets/images/textures/christmas/cloud_night.jpg'), type: IMAGE,                                      id: 'Cloud_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_snowman_night.jpg'), type: IMAGE,                        id: 'SnowMan_night_texture'   })
  manifest.push({ src: require('../assets/images/textures/christmas/christmas_candle_night.jpg'), type: IMAGE,                         id: 'Candle_night_texture'   })
}
