import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { helmet } from '../../utils/helmet';

import { Styles } from '../../styles/base';
import { UIContextProvider } from '../../context/UIContext';
import { LoadingProvider } from '../../context/LoadingContext';

interface IAppLayoutProps {
  children: React.ReactNode;
}

const LayoutWrap = styled.div`
  display: block;

  height: 100%;
`;

const Loader = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1em;
  color: #ff00ff;
`;

// tslint:disable-next-line: no-default-export
export default ({ children }: IAppLayoutProps) => (
  <LoadingProvider>
    <UIContextProvider>
      <LayoutWrap>
        <Helmet {...helmet}>
          <style
            dangerouslySetInnerHTML={{
              __html: `
          html {
            --scale-font: 1;
          }
        `,
            }}
          />
        </Helmet>
        <Styles />

        {children}
      </LayoutWrap>
    </UIContextProvider>
  </LoadingProvider>
);
